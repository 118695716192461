.section.model {
    padding: 1rem;
    margin: 0;
}

.section.model .column {
    background-size: 25%;
    background-position: left calc(90% - 20px);
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-self: center;
    height: 100%;
    padding-top: 4rem;
    padding-bottom: 4rem;
}

.section.model .column.right {
    background-position: right calc(10% + 20px);
}

@media (max-width: 600px) {
    .section.model .column {
        padding-top: 2rem;
        padding-bottom: 1rem;
    }
}

@media (max-width: 1279px) {
    .section.model .column {
        background-image: none !important;
    }
}
