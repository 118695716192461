#contact .section {
    padding: 1rem 2rem;
    margin: 0;
}

#contact .section .column {
    background-size: 7%;
    background-position: left bottom;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-self: center;
    height: 100%;
    padding-top: 4rem;
    padding-bottom: 2rem;
}

#contact .section .column.right {
    background-position: right 4rem;
}

@media (max-width: 600px) {
    #contact .section .column {
        padding-top: 2rem;
        padding-bottom: 1rem;
    }
}

@media (max-width: 1279px) {
    #contact .section .column {
        background-image: none !important;
    }
}

form .mdc-layout-grid__cell label {
    min-width: 100%;
}

.mdc-button.dark {
    background-color: #616161;
}

.addresses {
    font-family: var(--ubuntu-font);
    font-weight: 400;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-bottom: 2rem;
}

.addresses img {
    height: 120px;
    width: auto;
    margin-left: .8rem;
}

@media (max-width: 799px) {
    .addresses img {
        display: none;
    }
}

.address {
    flex-basis: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-top: 1rem;
    padding-bottom: 1rem;
}

.address .country {
    min-width: 120px;
    align-self: center;
    flex-basis: 50%;
    text-align: center;
}

.address .details {
    flex-basis: 100%;
}

@media (min-width: 400px) {
    .address .country {
        flex-basis: 70%;
    }
}

@media (min-width: 600px) {
    .addresses {
        grid-template-columns: 1fr;
    }

    .address .country {
        flex-basis: 100%;
    }
}

@media (min-width: 1024px) {
    .addresses {
        flex-direction: row;
        padding: 3rem 6rem 4rem;
    }

    .address .country {
        flex-basis: 60%;
    }
}
