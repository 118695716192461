:root {
    --mdc-typography-headline1-font-family: var(--baloo-font);
    --mdc-typography-headline2-font-family: var(--baloo-font);
    --mdc-typography-headline3-font-family: var(--baloo-font);
    --mdc-typography-headline4-font-family: var(--baloo-font);
    --mdc-typography-headline5-font-family: var(--baloo-font);
    --mdc-typography-headline6-font-family: var(--baloo-font);
    --mdc-typography-subtitle1-font-family: var(--ubuntu-font);
    --mdc-typography-subtitle2-font-family: var(--ubuntu-font);
    --mdc-typography-button-font-family: var(--ubuntu-font);
    --mdc-typography-font-family: var(--ubuntu-font);
    --mdc-theme-error: #d43551;
    --toolbar-height: 84px;
    --content-top-padding: .8rem;
    --toolbar-side-margin: 2rem;
    --mdc-typography-button-text-transform: none;
}

body {
    color: #727272;
}

.mdc-typography--button,
.mdc-button,
.mdc-fab__label,
.mdc-tab {
    letter-spacing: 0.05715em;
}

.mdc-top-app-bar {
    top: 0 !important;
}

.mdc-top-app-bar__row {
    height: var(--toolbar-height);
    margin-left: var(--toolbar-side-margin);
    margin-right: var(--toolbar-side-margin);
    font-family: var(--ubuntu-font);
}

.mdc-top-app-bar__section--align-end {
    margin-right: calc(var(--toolbar-side-margin) * 2);
}

.mdc-top-app-bar--fixed-adjust {
    padding-top: var(--toolbar-height);
}

.mdc-top-app-bar__title {
    font-weight: 700;
    padding-left: 16px;
}

.mdc-top-app-bar,
.mdc-top-app-bar--fixed {
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, .2), 0 4px 5px 0 rgba(0, 0, 0, .14), 0 1px 10px 0 rgba(0, 0, 0, .12);
}

.mdc-top-app-bar a {
    text-decoration: none !important;
    color: var(--mdc-theme-on-primary);
}

.mdc-body {
    min-height: calc(100vh - var(--toolbar-height) - var(--content-top-padding, .8rem));
    padding: 0 1.6rem .8rem;
}

.mdc-snackbar {
    bottom: 1.2rem;
}

.mdc-snackbar--leading {
    left: 1.2rem;
}

.mdc-drawer__header {
    min-height: var(--toolbar-height);
}

.mdc-divider {
    height: 1px;
    background-color: rgba(0, 0, 0, .12);
}

.mdc-list-item__graphic.mdi {
    margin-top: .2rem;
    margin-right: 24px;
}

a.toolbar-logo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    line-height: 1;
    height: var(--toolbar-height, 100%);
    max-height: 100%;
    margin: 1.6rem .8rem;
}

a.toolbar-logo img {
    display: block;
    height: var(--toolbar-height, 100%);
    width: auto;
    max-width: 100%;
    max-height: calc(100% - 2.4rem);
}

.mdc-text-field__input {
    font-family: var(--system-font);
}

.mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-floating-label,
body .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-floating-label {
    color: var(--mdc-theme-error, #b00020);
}

.mdc-fab .mdc-fab__label {
    font-size: 1rem;
}

.mdc-fab--extended .mdc-fab__icon {
    margin-left: -4px;
    margin-right: 8px;
}

.mdc-fab.positioned {
    position: fixed;
    bottom: 1.6rem;
    right: 1.6rem;
}

.mdc-drawer__content a {
    text-decoration: none;
}

button.mdc-button .mdi {
    margin-bottom: .2rem;
}

.mdc-top-app-bar .mdc-button.full-height {
    height: 100%;
    padding-left: 1.6rem;
    padding-right: 1.6rem;
    font-weight: 500;
}

.mdc-top-app-bar .mdc-button.full-height .mdc-button__ripple {
    border-radius: 0;
}

.mdc-top-app-bar__section {
    padding-top: 0;
    padding-bottom: 0;
}

.mdc-button {
    min-height: 42px;
    padding-left: 1.2rem;
    padding-right: 1.2rem;
}

.mdc-button.primary {
    background-color: #1977ff;
    color: #fff;
}

.mdc-button.secondary {
    background-color: #6ec901;
    color: #0a3066;
}

.mdc-button.rounded,
.mdc-button.rounded .mdc-button__ripple {
    border-radius: 9999999px;
}

.mdc-button.inverted {
    color: #1977ff;
    background-color: #fff;
}

.mdc-top-app-bar .mdi.mdi-menu,
.mdc-top-app-bar .mdi.mdi-menu:before {
    color: rgba(0, 0, 0, 0.7)
}

.block-mobile {
    display: none;
}

.flex-mobile {
    display: none;
}

.hidden-mobile {
    display: block;
}

.mdc-drawer .mdc-list-item {
    padding-left: .8rem;
    padding-right: .8rem;
}

.mdc-drawer .mdc-list-item.drawer-item-no-ripple {
    padding-top: .2rem;
    padding-bottom: .8rem;
    margin-top: 0;
    margin-bottom: 0;
}

.drawer-item-no-ripple,
.drawer-item-no-ripple:before,
.drawer-item-no-ripple:after {
    background: none !important;
    color: rgba(0, 0, 0, 0) !important;
    --mdc-ripple-fg-opacity: 0 !important;
}

.drawer-item-no-ripple:before,
.drawer-item-no-ripple:after {
    opacity: 0 !important;
}

.mdc-button__label {
    text-align: center;
}

.mdc-select.mdc-select--focused .mdc-select__dropdown-icon {
    color: var(--mdc-theme-primary);
    fill: var(--mdc-theme-primary);
}
