.call-dialog-logo {
    width: 92px;
    height: 92px;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    margin: 2rem auto;
    display: block;
    background: var(--mdc-theme-primary);
}

.dialog-field {
    margin: 1.2rem 0;
}

.dialog-field .mdc-text-field,
.dialog-field .mdc-select {
    width: 100%;
}

.dialog-field:first-of-type {
    margin-top: 1.6rem;
}

.dialog-field:last-of-type {
    margin-top: 2.8rem;
    margin-bottom: .6rem;
}

.dialog-field .mdc-button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    min-height: 48px;
    min-width: 84px;
    margin: 0 auto;
}

.dialog-field .mdc-button,
.dialog-field .mdc-button .mdc-button__ripple {
    border-radius: 99999px;
}

.dialog-field .mdc-button svg,
.dialog-field .mdc-button img {
    display: block;
    height: 30px;
    width: 30px;
    color: #fff;
    fill: #fff;
    margin: 0;
    padding: 0;
}

.calling {
    font-weight: bold;
    padding-bottom: 1.5em;
    text-align: center;
}
.calling-status {
    color: #64DC17;
}

.callout {
    color:red;
}

.calling-info {
    margin: auto;
    padding: 1.5em;
    color: #b3b3b3;
    text-align: center;
}