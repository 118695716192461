:root {
    --system-font: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
    --manrope-font: 'Manrope', var(--system-font);
    --baloo-font: 'Baloo Bhaina 2', var(--system-font), cursive;
    --ubuntu-font: 'Ubuntu', var(--system-font);
    --primary: #0069ff;
    --on-primary: #fff;
    --on-secondary: #002a66;
    --secondary: #64dc17;
}

*, html, body, main {
    scroll-behavior: smooth;
}

.use-manrope {
    font-family: var(--manrope-font);
}

.use-baloo {
    font-family: var(--baloo-font);
}

.use-ubuntu {
    font-family: var(--ubuntu-font);
}

img:not([alt]) {
    background-color: #f00;
}

body {
    margin: 0;
    font-family: var(--ubuntu-font);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #000;
    font-size: 1rem;
    line-height: 1.5;
    font-weight: 500;
    background: #fff;
}

h1 {
    font-family: var(--mdc-typography-headline1-font-family, var(--manrope-font));
    font-size: 2.8rem;
    font-weight: 800;
}

h2 {
    font-family: var(--mdc-typography-headline2-font-family, var(--manrope-font));
    font-size: 2.4rem;
    font-weight: 700;
}

h3 {
    font-family: var(--mdc-typography-headline3-font-family, var(--manrope-font));
    font-size: 2.0rem;
    font-weight: 700;
}

h4 {
    font-family: var(--mdc-typography-headline4-font-family, var(--manrope-font));
    font-size: 1.6rem;
    font-weight: 600;
}

h5 {
    font-family: var(--mdc-typography-headline5-font-family, var(--manrope-font));
    font-size: 1.3rem;
}

h6 {
    font-family: var(--mdc-typography-headline6-font-family, var(--manrope-font));
}

b {
    font-weight: 600;
}

p {
    font-size: 1rem;
    margin: 0;
}

a {
    color: var(--mdc-theme-primary);
    text-decoration: underline solid var(--mdc-theme-primary);
}
