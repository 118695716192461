@media (min-width: 1280px) {
    :not(#solutions) .section .column {
        padding-left: 7rem;
        padding-right: 7rem;
    }
}

.benefits-grid {
    display: grid;
    grid-template-columns: 1fr;
    background-color: #fff;
}

.benefit {
    display: flex;
    flex-direction: column;
    padding: 2rem 3rem 2rem 1rem;
    height: 100%;
    background-position: right center;
    background-repeat: no-repeat;
    background-size: 12%;
}

.benefit:last-child {
    background-image: none !important;
}

.benefit .title {
    font-family: var(--ubuntu-font);
    font-weight: 500;
    font-size: 1.5rem;
    color: var(--mdc-theme-primary);
    margin-top: 1.6rem;
    margin-bottom: 1.6rem;
}

.benefit .description {
    font-family: var(--ubuntu-font);
    font-weight: 400;
    color: #656565;
}

@media (min-width: 600px) {
    .benefits-grid {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media (min-width: 1280px) {
    .benefits-grid {
        grid-template-columns: repeat(5, 1fr);
        margin-left: 6.5rem;
        margin-right: 6.5rem;
    }
}

@media (max-width: 599px) {
    .benefit {
        background-image: none !important;
    }
}

@media (min-width: 600px) and (max-width: 1279px) {
    .benefit:nth-child(3n) {
        background-image: none !important;
    }
}
