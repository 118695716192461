.check-bg {
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    height: 114px;
    margin-bottom: 1.2rem;
}

.check-bg img {
    display: block;
    position: relative;
    max-width: 70px;
    margin-left: auto;
    margin-right: calc(50% - 74px);
    top: calc(calc(50% - 40px) * -1);
}
