@media (min-width: 1280px) {
    :not(#solutions) .section .column.right {
        padding-left: 1rem;
        padding-right: 6rem;
    }
}

.column .about-img {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.column .about-img span {
    font-family: var(--ubuntu-font);
    font-weight: 400;
    text-align: center;
    color: #656565;
}

.column .about-img span.primary {
    font-weight: 500;
    color: var(--mdc-theme-primary);
}

.column .about-img .first,
.column .about-img .third {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.column .about-img .second {
    width: 100%;
    display: grid;
    grid-template-columns: 20% 60% 20%;
}

.column .about-img .second .child {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.column .about-img .second img {
    margin: auto;
    width: 100%;
    max-width: 450px;
}

@media (max-width: 800px) {
    .column .about-img span {
        font-size: .7rem;
    }
}
