.intro-video {
    position: absolute;
    top: var(--toolbar-height);
    z-index: -1;
    margin: 0 auto;
    padding: 0;
    height: auto;
    min-height: 100vh;
    width: 100vw;
    max-width: 100%;
    object-fit: cover;
}

.section {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    padding: 4rem 1.6rem 8rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.section.intro {
    background-color: rgba(0, 74, 179, .35);
}

.section .title {
    font-size: 2.8rem;
}

.section .content {
    margin: 2rem 4rem;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    align-items: center;
    height: 100%;
    max-height: 100%;
    max-width: 100%;
}

.section .content *:not(.mdc-button) {
    width: 100%;
}

.section .img-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    /*
    height: 100%;
    max-height: 100%;
    max-width: 100%;
    margin: 4rem;
    */
}

.section .img-container img {
    display: block;
    margin: auto;
    max-width: 350px;
}

@media (max-width: 600px) {
    .section {
        grid-template-columns: 1fr;
        padding-top: 4rem;
        padding-bottom: 4rem;
    }

    .section {
        padding: 1rem 1.2rem 3rem;
        word-break: break-word;
    }

    .section .content {
        margin: 1rem 0;
    }

    .section .img-container {
        margin-top: 2rem;
        margin-bottom: 1rem;
    }
}

@media (min-width: 1280px) {
    :root {
        --toolbar-side-margin: 6rem;
    }

    .section {
        padding-top: 4rem;
        padding-left: 6rem;
        padding-right: 6rem;
    }

    .section .title {
        font-size: 3.3rem;
    }
}

@media (min-width: 1441px) {
    .section.intro .content {
        margin: 2rem 8rem;
    }
}

@media (min-width: 1500px) {
    .section.intro .content {
        margin: 2rem 10rem;
    }
}

.lfm__typing_effect_cursor,
.Typewriter__cursor {
    font-weight: 600;
}

@media (min-width: 650px) {
    .section.intro {
        background-image: none !important;
        grid-template-columns: 65% 35%;
    }
}

@media (max-height: 500px) {
    .intro-video {
        height: auto;
        min-height: 175vh;
    }
}

/*
@media (max-width: 649px) {
    .intro-video {
        display: none;
        width: 0;
        height: 0;
        top: 0;
        max-width: 0;
    }
}
*/
