.chat-box .titlebar {
  width: 100%;
  padding: .4rem;
  max-width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-self: flex-start;
  background-color: var(--mdc-theme-primary, #1977ff);
  color: var(--mdc-theme-on-primary, #fff);
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

.chat-box .titlebar div {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  align-self: flex-start;
  color: var(--mdc-theme-on-primary, #fff);
}

.chat-box .titlebar div:first-of-type {
  flex: 1;
}

.chat-box .titlebar div img {
  max-width: 48px;
}

.chat-box .titlebar button {
  color: #fff;
  max-width: 48px;
  min-width: 48px;
  max-height: 48px;
  min-height: 48px;
}
