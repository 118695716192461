.banner-web {
    width: 900px;
    margin-top: 20px;
}

.bpsmart-logo-cxsummit {
    width: 92px;
    height: 92px;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    margin: 2rem auto;
    display: block;
    background: var(--mdc-theme-primary);
    
}.cx-summit {
    display: flex;
    align-items: center;
    flex-direction: column;
}

form {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-bottom: 15px;
}

.trivia-component-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: fixed;
    bottom: 1.6rem;
    z-index: 10000000000;
    height: 85%;
    width: 65%;
    max-width: 65%;
    background-color: #fff;
    border-radius: 6px;
    box-shadow: 0 6px 12px 0 rgb(0 0 0 / 50%);
}

.trivia-component-container,
.trivia-component-container * {
    box-sizing: border-box;
}

.trivia-component-container .chat-box {
    border-radius: 6px
}

.trivia-component-container .chat-box {
    max-height: 96vh;
    width: 100%;
    max-width: 100%;
}

.privacy-data {
    max-width: 425px;
    text-align: justify;
    background: '#fff';
}

.button-sign {
    min-width: 350px;
}

.load-chip {
    display: flex;
    min-width: 200px;
    min-height: 42px;
    padding-left: 1.2rem;
    padding-right: 1.2rem;
    justify-content: center;
}

@media (max-width: 2560px) {
    .trivia-component-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: fixed;
        bottom: 1.6rem;
        right: 30rem;
        height: 85%;
        width: 65%;
        max-width: 65%;
        background-color: #fff;
        border-radius: 6px;
        box-shadow: 0 6px 12px 0 rgb(0 0 0 / 50%);
    }
}

@media (max-width: 1920px) {
    .trivia-component-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: fixed;
        bottom: 1.6rem;
        right: 20rem;
        height: 85%;
        width: 65%;
        max-width: 65%;
        background-color: #fff;
        border-radius: 6px;
        box-shadow: 0 6px 12px 0 rgb(0 0 0 / 50%);
    }
}

@media (max-width: 1440px) {
    .trivia-component-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: fixed;
        bottom: 1.6rem;
        right: 15rem;
        height: 85%;
        width: 65%;
        max-width: 65%;
        background-color: #fff;
        border-radius: 6px;
        box-shadow: 0 6px 12px 0 rgb(0 0 0 / 50%);
    }
}

@media (max-width: 1024px) {
    .banner-web {
        width: 800px;
        margin-top: 20px;
    }
    .trivia-component-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: fixed;
        bottom: 1.6rem;
        right: 10rem;
        height: 85%;
        width: 65%;
        max-width: 65%;
        background-color: #fff;
        border-radius: 6px;
        box-shadow: 0 6px 12px 0 rgb(0 0 0 / 50%);
    }
}

@media (max-width: 768px) {
    .banner-web {
        width: 700px;
        margin-top: 20px;
    }
    .trivia-component-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: fixed;
        bottom: 1.6rem;
        right: 8rem;
        height: 85%;
        width: 65%;
        max-width: 65%;
        background-color: #fff;
        border-radius: 6px;
        box-shadow: 0 6px 12px 0 rgb(0 0 0 / 50%);
    }
}

@media (max-width: 625px) {
    .banner-web {
        width: 350px;
        margin-top: 20px;
    }
    .trivia-component-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: fixed;
        bottom: 1.6rem;
        right: 0.5rem;
        height: 85%;
        width: 95%;
        max-width: 95%;
        background-color: #fff;
        border-radius: 6px;
        box-shadow: 0 6px 12px 0 rgb(0 0 0 / 50%);
    }
}