#solutions .section.solutions {
    grid-template-columns: 1fr;
}

@media (min-width: 1024px) {
    #solutions .section.solutions {
        grid-template-columns: 40% 1fr;
    }
}

.section-container {
    padding: 6rem 12rem;
}

.section-pre-title {
    color: #888;
    font-family: var(--baloo-font);
    font-size: 2rem;
}

.section-title {
    font-family: var(--baloo-font);
    color: var(--mdc-theme-primary);
    font-size: 3.2rem;
    font-weight: 600;
    line-height: 1.3;
}

.section-body {
    font-family: var(--ubuntu-font);
    font-size: 1rem;
    font-weight: 400;
}

.solution-title {
    color: var(--mdc-theme-primary);
    font-family: var(--ubuntu-font);
    font-weight: 500;
    font-size: 2rem;
}

.solution-content {
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
}

.solution-preview-container {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
}

.solution-preview-container .solution-preview {
    height: 350px;
    width: auto;
    margin: auto;
    align-self: flex-start;
}

.solution-preview-container img,
.solution-preview-container video {
    border: 1px solid rgba(0, 0, 0, .18);
    border-radius: 8px;
    outline: none !important;
}

.solutions {
    padding-top: 2rem;
    padding-bottom: 0;
}

.solutions .column {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 4rem;
    padding-bottom: 2rem;
    background-size: 256px;
    background-repeat: no-repeat;
    background-position: left bottom;
}

.solutions .column.right {
    background-position: right top;
}

.solution-content .solution-icon {
    max-width: 144px;
    margin-right: 1rem;
}

.solution-content .test-btns-container {
    display: inline-flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    min-height: 64px;
    padding-left: 1rem;
    padding-right: .6rem;
    padding-top: .1rem;
    padding-bottom: .1rem;
    border-radius: 999999px;
    border: 1px solid var(--mdc-theme-primary);
    font-family: var(--ubuntu-font);
    font-weight: 400;
    color: var(--mdc-theme-primary);
    min-width: 21em;
}

.solution-content .test-btns-container .test-btn {
    border-radius: 50%;
    min-width: 48px;
    height: 48px;
    width: 48px;
    background-color: white;
    margin-left: .8rem;
    -webkit-transition: all .25s ease-in-out;
    -o-transition: all .25s ease-in-out;
    -moz-transition: all .25s ease-in-out;
    transition: all .25s ease-in-out;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.3);
}

.solution-content .test-btns-container .test-btn:first-of-type {
    margin-left: 1.6rem;
}

.solution-content .test-btns-container .test-btn:hover,
.solution-content .test-btns-container .test-btn:focus {
    background-color: var(--mdc-theme-primary);
    transform: translateY(-.1rem);
}

.solution-content .test-btns-container .test-btn svg,
.solution-content .test-btns-container .test-btn img {
    --svg-fill-color: #0a3066;
    display: block;
    height: 30px;
    width: 30px;
    color: var(--svg-fill-color);
    fill: var(--svg-fill-color);
    -webkit-transition: all .25s ease-in-out;
    -o-transition: all .25s ease-in-out;
    -moz-transition: all .25s ease-in-out;
    transition: all .25s ease-in-out;
}

.solution-content .test-btns-container .test-btn svg .cls-1 {
    color: var(--svg-fill-color);
    fill: var(--svg-fill-color);
    -webkit-transition: all .25s ease-in-out;
    -o-transition: all .25s ease-in-out;
    -moz-transition: all .25s ease-in-out;
    transition: all .25s ease-in-out;
}

.solution-content .test-btns-container .test-btn:hover img,
.solution-content .test-btns-container .test-btn:focus img {
    --svg-fill-color: #fff;
    color: var(--svg-fill-color);
    fill: var(--svg-fill-color);
}

.solution-content .test-btns-container .test-btn:hover svg .cls-1,
.solution-content .test-btns-container .test-btn:focus svg .cls-1 {
    --svg-fill-color: #fff;
}

@media (max-width: 800px) {
    .solution-content {
        flex-direction: column;
    }
    .solution-content .test-btns-container {
        min-height: 64px;
        padding-left: 1rem;
        padding-right: .6rem;
        padding-top: .1rem;
        padding-bottom: .1rem;
        min-width: 10em;
        width: 90%;
    }

    .solution-content .test-btns-container .test-btn {
        min-width: 30px;
        height: 30px;
        width: 30px;
        margin-left: .3rem!important;
    }
}

@media (min-width: 1280px) {
    .section.solutions {
        padding-left: 1rem;
        padding-right: 1rem;
        margin-bottom: 6rem;
    }

    .solutions .column {
        padding-left: 8rem;
        padding-right: 8rem;
    }

    .solution-content {
        padding-left: 2rem;
        padding-right: 2rem;
    }

    #solutions .section .column.right.description {
        padding-left: 1rem;
        padding-right: 7rem;
    }
}

@media (max-width: 1279px) {
    .solutions .column {
        padding-top: 1rem;
        padding-bottom: 1rem;
        background-image: none !important;
    }
}

@media (max-width: 354px) {
    .solution-content .test-btns-container .test-btn {
        min-width: 25px;
        height: 25px;
        width: 25px;
        margin-left: .1rem!important;
    }

    .test-btns-container p {
        font-size: .6rem;
    }
}
