.footer {
    display: none;
    flex-direction: row;
    background-color: #424242;
    height: var(--toolbar-height);
    padding-right: var(--toolbar-side-margin);
    padding-left: var(--toolbar-side-margin);
}

.footer * {
    flex-basis: 100%;
    width: 100%;
}

.footer .mdc-button {
    color: #fff;
    height: 100%;
    border-radius: 0;
    padding-left: 1.2rem;
    padding-right: 1.2rem;
}

.footer .mdc-button .mdc-button__ripple {
    border-radius: 0;
}

.footer a,
.footer a:focus,
.footer a:hover,
.footer a:active,
.footer a:visited {
    text-decoration: none !important;
}

.footer .social {
    display: flex;
    flex-direction: row;
}

.footer .social .mdc-button .mdi {
    margin: 0 0 2.4rem;
}

.footer .social .mdc-button .mdi:before {
    font-size: 36px;
}

.footer .social .mdc-button .mdc-button__label {
    display: none;
}

.footer .mdc-button.logo {
    padding-left: 2rem;
    padding-right: 2rem;
}

.footer .mdc-button.logo img {
    display: block;
    margin: .4rem auto;
    max-height: calc(var(--toolbar-height) - 24px);
}

.footer a.icon-link {
    text-align: center;
}

.copyright {
    font-family: var(--ubuntu-font);
    background-color: #888;
    padding: 1.2rem .8rem;
    text-align: center;
    color: #fff;
    text-transform: uppercase;
}

@media (min-width: 800px) {
    .footer {
        display: flex;
    }
}
