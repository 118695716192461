.chat-box {
  max-height: 95vh;
  max-width: 400px;
}

.chat-box .titlebar {
  max-width: 100%;
  box-sizing: border-box;
}

.mdc-dialog .mdc-dialog__surface {
  border-radius: 6px;
}

h2.mdc-dialog__title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: .8rem;
}

h2.mdc-dialog__title div {
  display: flex;
  flex-direction: row;
  align-items: center;
}

h2.mdc-dialog__title div:first-of-type {
  flex: 1;
}

h2.mdc-dialog__title div:first-of-type span {
  padding-top: .4rem;
}

h2.mdc-dialog__title div img {
  max-width: 48px;
  margin-right: 1rem;
}

.mdc-dialog__button {
  min-width: 48px;
  width: 48px;
  max-width: 48px;
  min-height: 48px;
  height: 48px;
  max-height: 48px;
}

@media (min-width: 960px) {
  .mdc-dialog .mdc-dialog__surface {
    min-width: 400px;
    max-width: 800px;
  }
}
